import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import ParticlesComponent from "./particles";

function App() {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [imageUrl, setImageUrl] = useState("");
  const [imageStatus, setImageStatus] = useState("");
  const [showCertificate, setShowCertificate] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9.!#$%&'+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;
    return re.test(email);
  };

  const downloadImage = async () => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "RTLCertificate.png"); // The file will be downloaded as RTLCertificate.png
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up after download
    } catch (error) {
      console.error("Error fetching and downloading image:", error);
    }
  };

  const showImage = () => {
    if (!validateEmail(email)) {
      setIsValidEmail(false);
      return;
    }

    const url = `https://raw.githubusercontent.com/eXtizi/myFTP/main/rtl/${email}.png`;
    setImageUrl(url);
    setIsValidEmail(true);
    setShowCertificate(true);

    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageStatus("");
    };
    img.onerror = () => {
      setImageStatus("No certificate available.");
    };
  };

  return (
    <div className="container mt-5">
      <ParticlesComponent id="particles-background" />
      {!showCertificate && (
        <>
          <div className="row justify-content-center" id="image1" style={{ marginTop: "100px" }}>
            <div className="col-md-6 text-center">
              <img
                src="hkx.jpg"
                style={{ height: "auto", width: "100%", maxWidth: "500px" }}
                alt="Road to Legacy"
              />
            </div>
          </div>

          <div className="container mt-5" id="inputbox">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Get Your Road to Legacy Certificate</h5>
                    <div className="form-group">
                      <label htmlFor="emailInput">Enter your email:</label>
                      <input
                        type="email"
                        className={`form-control ${!isValidEmail ? "is-invalid" : ""}`}
                        id="emailInput"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="example@example.com"
                      />
                      {!isValidEmail && (
                        <div className="invalid-feedback">Please enter a valid email.</div>
                      )}
                    </div>
                    <button className="btn btn-primary" onClick={showImage}>
                      Get Certificate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5" id="thank">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <p>
                      <br />
                      "As you move forward in life, we encourage you to engage in research,
                      innovations, and endeavors that contribute positively to society. That is the
                      best way to give back to our country and hopefully to make a better one. I
                      hope Road to Legacy event impacted you just a little bit in that journey."
                      <br />
                      <br />
                      Thank you so much. Best Wishes!
                      <br />
                      <br />
                      ~ Geethika Isuru - Chief Organizer, RTL 1.0
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showCertificate && (
        <div className="container mt-5" id="imageSection" style={{marginBottom:"40px"}}>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card">
                <img
                  className="card-img-top"
                  id="emailImage"
                  src={imageUrl}
                  alt="Email Certificate"
                  style={{ display: imageStatus ? "none" : "block" }}
                />
                <div className="card-body">
                  <p id="imageStatus" style={{ color: imageStatus ? "red" : "green" }}>
                    {imageStatus ? imageStatus : "Your certificate is ready!"}
                  </p>
                  {imageStatus === "" && (
                    <button className="btn btn-success btn-block" onClick={downloadImage}>
                      Download Certificate
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
